<template>
    <div>
        <Navigation />
        <div class="body-wrap">
            <section>
                <h1 class="text-2xl font-black text-primary text-center">Help When You Need It</h1>
                <div class="my-5 flex flex-col sm:flex-row justify-center">
                    <a class="btn-big text-2xl" href="tel:1.888.535.5776">
                        <span class="block text-base">Phone Support</span> 1.888.535.5776
                    </a>
                    <a class="btn-big text-2xl" href="https://forum.kellpro.com">
                        <span class="block text-base">Discussions and Tips</span> Visit Forum
                    </a>
                    <a class="btn-big text-2xl" href="mailto:support@kellpro.com">
                        <span class="block text-base">Email Support</span> support@kellpro.com
                    </a>
                </div>
            </section>
            <section>
                <h1 class="text-2xl font-black text-primary text-center">Support Options</h1>
                <div class="my-5 flex justify-center">
                    <a
                        v-for="(support, index) in supportOptions"
                        :key="index"
                        :href="support.link"
                        class="btn-small"
                    >{{ support.name }}</a>
                </div>
            </section>
            <address>
                <div>
                    <div>
                        <p class="uppercase">Address</p>
                    </div>
                    <div>
                        <a
                            href="http://maps.google.com/?q=KellPro"
                            target="_blank"
                            class="font-bold text-lg hover:text-secondary"
                        >101 South 15th Street <br/> Duncan, OK 73533</a>
                    </div>
                </div>
                <div class="mt-10">
                    <p class="uppercase">Phone number</p>
                    <ul>
                        <li><a class="font-bold text-lg hover:text-secondary" href="tel:888.535.5776">888.535.5776</a></li>
                        <li><a class="font-bold text-lg hover:text-secondary" href="tel:580.255.5553">580.255.5553</a></li>
                        <li><span class="font-bold text-lg">580.252.4009 /fax/</span></li>
                    </ul>
                </div>
                <div class="mt-10">
                    <p class="uppercase">Hours of operation</p>
                    <p class="font-bold text-lg">Monday - Friday: 8am-5pm</p>
                    <p class="font-bold text-lg">except national holidays</p>
                </div>
                <div class="mt-10">
                    <p class="uppercase">email</p>
                    <ul>
                        <li><a class="font-bold text-lg hover:text-secondary" href="mailto:sales@kellpro.com">sales@kellpro.com</a></li>
                        <li><a class="font-bold text-lg hover:text-secondary" href="mailto:support@kellpro.com">support@kellpro.com</a></li>
                    </ul>
                </div>
            </address>
        </div>
    </div>
</template>

<script>
    import Navigation from "../components/Navigation";

    export default {
        name: "Support",
        components: {Navigation},
        data(){
            return {
                supportOptions: [
                    {name: 'On Demand', link: 'https://kellpro.screenconnect.com/'},
                    {name: 'Join.me', link: 'https://www.join.me/'},
                    {name: 'TeamViewer', link: 'http://download.teamviewer.com/download/TeamViewerQS_en.exe'},
                ]
            }
        }
    };
</script>

<style scoped>
    address {
        @apply mx-auto w-full md:w-3/4 lg:w-2/5 my-20 p-4 py-10 border border-primary rounded-lg shadow-lg text-primary text-center not-italic;
    }
    .btn-big {
        @apply py-5 my-3 sm:mx-3 w-full sm:w-1/3 md:w-1/4 bg-secondary rounded-lg font-bold text-yellow-900 text-center hover:bg-yellow-500;
    }
    .btn-small {
        @apply w-2/6 sm:w-1/6 py-3 mx-2 bg-blue-700 rounded-md font-bold text-white text-center;
    }
</style>